.match_display_unit {
  height: 150px;
  width: 100%;
}

body {
  font-family: sans-serif !important;
}

/*For Desktop   */
.thisoverrunmob {
  height: 40px;
  text-align: center;
  color: white;
  margin-left: 5px;
  float: left;
  width: 7%;
}

.thisoverwicketmob {
  border-radius: 100%;
  background-color: red;
  height: 19px;
}
.thisoverrundsk {
  height: 40px;
  text-align: center;
  color: white;
  margin-left: 25px;
  float: left;
  width: 3.5%;
}
.thisoverwicketdsk {
  border-radius: 50%;
  background-color: red;
  height: 22px;
}
.scoredetails {
  font-size: 12px;
  color: gray;
  padding-top: 5px;
  text-align: center;
  font-family: "open sans", "Helvetica Neue", "Helvetica";
}
.scoredetailsrun {
  font-size: 15px;
  color: white;
  padding-top: 5px;
  text-align: center;
}

.col-1 {
  width: 83.5%;
}
.col-2 {
  width: 16.5%;
}
.cal-3 {
  width: 100%;
}
.col-6 {
  width: 27%;
  background-color: #131f29;
  color: white;

  float: left;
  font-family: "open-sans-regular" !important;
}
.col-7 {
  width: 46%;
  background-color: #131f29;
  color: white;

  float: left;
}

.col-8 {
  width: 27%;
  background-color: #131f29;
  color: white;
}
.col-9 {
  width: 100%;
  background-color: #131f29;
}
.col-m1 {
  width: 100%;
  background-color: #131f29;
  display: none;
}
.col-d1 {
  width: 100%;
  background-color: #131f29;
  display: block;
}

/* .col-4{
width: 100%;
padding-right: 20%;
padding-left: 20%;

}
.col-5{
width: 20%;
padding-right: 30%;
padding-left: 30%;

} */
[class*="col-"] {
  float: left;
  padding: 15px;
}
.displaydiv_desktop {
  /* background-color: red; */
  height: auto;
}
.displaydiv_mobile {
  /* background-color: rgb(26, 138, 95); */
  height: auto;
}
.Inplay_date_on {
  background-color: #983838;
  height: 40px;
  text-align: center;
}
.Inplay_date_off {
  background-color: #444d53 !important;
  height: 40px;
  padding-top: 9px;
  font-size: 14px;
  font-family: "Roboto", "sans-serif" !important;
  color: #fff !important;
}
[class*="Inplay_date_off"] {
  display: none;
}
[class*="displaydiv_desktop"] {
  display: block;
}
[class*="displaydiv_mobile"] {
  display: none;
}
.inningsdesktop {
  height: 80px;
  margin-top: 6px;
  border-radius: 10px;
  background-color: black;
  padding-top: 25px;
  text-align: center;
  font-size: 20px;
  color: #ffb80c;
  width: 170px;
  margin-left: 50px;
  display: block;
}
.inningsmob {
  height: 80px;
  margin-top: 6px;
  border-radius: 10px;
  background-color: black;
  padding-top: 25px;
  text-align: center;
  font-size: 20px;
  color: #ffb80c;
  display: none;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  /* .container.dashboard_content{
    margin-top: 70px;
    } */
  .vertical_mid {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
  }

  [class*="col-5"] {
    width: 100%;
  }

  [class*="col-6"] {
    float: left;
    width: 27%;
    color: white;
  }
  [class*="col-7"] {
    width: 46%;
    float: left;
    color: white;
  }
  [class*="col-8"] {
    width: 27%;
    float: left;
    color: white;
  }
  [class*="col-9"] {
    width: 100%;
    float: left;
    color: gray;
  }
  [class*="col-10"] {
    width: 100%;
    float: left;
    color: white;
  }
  [class*="Inplay_date_on"] {
    display: block;
  }
  [class*="col-2"] {
    display: none;
  }
  [class*="col-1"] {
    border-top-left-radius: 0px;
  }
  [class*="displaydiv_mobile"] {
    display: block;
  }
  [class*="displaydiv_desktop"] {
    display: none;
  }
  .inningsmob {
    height: 80px;
    margin-top: 6px;
    border-radius: 10px;
    background-color: black;
    padding-top: 25px;
    text-align: center;
    font-size: 20px;
    color: yellow;
    display: block;
  }
  .inningsdesktop {
    height: 80px;
    margin-top: 6px;
    border-radius: 10px;
    background-color: black;
    padding-top: 25px;
    text-align: center;
    font-size: 20px;
    color: yellow;
    width: 170px;
    margin-left: 127px;
    display: none;
  }
  .col-m1 {
    width: 100%;
    background-color: #131f29;
    display: block;
  }
  .col-d1 {
    width: 100%;
    background-color: #131f29;
    display: none;
  }
  .user_profile {
    float: right;
    color: #fd8b5c;
  }
}

/* css for home attributes */
.card.card-1 {
  line-height: 2;
}

.card.card-color {
  background-color: #983838 !important;
  color: #fff !important;
}
.card-1 {
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.card {
  background-color: #983838;
  border-radius: 20px;
  font-family: "open-sans-semibold" !important;
  height: 70px;
  margin: 1rem auto;
  position: relative;
  width: 230px;
  text-align: center;
  display: block;
  padding: 18px;
  font-size: 18px;
  letter-spacing: 2px;
  color: #fff !important;
}

a.card.card-color:hover {
  background-color: #fff !important;
  color: #983838 !important;
}
.card-1:focus,
.card-1:hover {
  text-decoration: none !important;
}

.card.card-1 {
  line-height: 2;
}
.card-1:hover {
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%), 0 4px 4px rgb(0 0 0 / 22%);
  background: #fff;
  text-decoration: none !important;
  color: #983838 !important;
}
.card.card-color {
  background-color: #983838 !important;
  color: #fff !important;
}
a:focus,
a:hover {
  color: #fff;
  text-decoration: none;
}
a:active,
a:hover {
  outline: 0;
}

.desktop_menupart {
  height: 50%;
  float: left;
  padding: 1% 5%;
}
table,
th,
td {
  border: 1px solid black;
}
.tableheader {
  vertical-align: middle;
  text-align: center;
  border: 5px solid whitesmoke;
  font-size: 13px;
  padding: 7px 0px;
  background-color: wheat;
  height: 40px;
  color: #000;
}
.input-num {
  height: 100px;
  width: 100%;
  text-align: center;
}
.icon-minus {
  height: 100px;
  width: 100%;
  background-image: linear-gradient(-180deg, #fff 0%, #eee 89%);
  justify-content: center;
  align-items: center;
}
.typed {
  height: 100px;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  font-size: 17px;
  font-family: "Times New Roman", Times;
}
.icon-plus {
  height: 100px;
  width: 100%;
  text-align: center;
  background-image: linear-gradient(-180deg, #fff 0%, #eee 89%);
  justify-content: center;
  align-items: center;
}
.betvalue_li {
  height: 36px;
  width: 16.66%;
  text-align: center;
  float: left;
  border-right: 1px solid whitesmoke;
  padding-top: 9px;
  list-style: none;
  color: antiquewhite;
}
.numpad {
  height: 39px;
  width: 16%;
  text-align: center;
  float: left;
  padding-top: 9px;
  list-style: none;
  color: "rgb(19, 31, 41)";
  background-color: whitesmoke;
  font-weight: bold;
  border-radius: 8%;
}
.betslipPlacebet_btn {
  background-color: #748da0;
  height: 35px;
  width: 90%;
  margin: 9px 10px;
  text-align: "center";
  border: 1px solid gray;
  border-radius: 20px;
  text-align: center;
  padding-top: 5px;
  font-size: 15px;
  color: #f9ead7;
}
.betslipcancel_btn {
  height: 35px;
  width: 90%;
  margin: 9px 10px;
  background-color: #fff;
  text-align: "center";
  border: 1px solid gray;
  border-radius: 20px;
  text-align: center;
  padding-top: 5px;
  font-size: 15px;
}
.fancy_bet_run {
  height: 30px;
  width: 100%;
  background-color: #f3e5e8;
  padding: 3px 10px;
  list-style: none;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.fancy_bet_yes_tab {
  height: 45px;
  width: 20%;
  float: left;
  border: 1px solid white;
  background-color: rgb(97, 172, 222);
  text-align: center;
  padding-top: 14px;
  font-weight: bold;
}
.fancy_bet_no_tab {
  height: 45px;
  width: 20%;
  float: left;
  border: 1px solid white;
  background-color: pink;
  text-align: center;
  padding-top: 14px;
  font-weight: bold;
}
.matchodds_team_name {
  height: 45px;
  width: 60%;
  float: left;
  text-align: left;
  padding: 10px 5px;
  color: #131f29;
  background-color: whitesmoke;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid white;
}
.match_odds_session_main_div {
  height: 40px;
  width: 100%;
  background-color: #374049;
  color: white;
}
.matchs_odd_session {
  height: 40px;
  width: 60%;
  float: left;
  text-align: left;
  padding: 8px 5px;
  font-size: 15px;
  font-weight: bold;
}
.match_odds_fancy {
  height: 40px;
  float: left;
  width: 20%;
  text-align: center;
  padding-top: 10px;
  font-size: 12px;
  font-weight: bold;
}
.inplayCriketheader {
  height: 35px;
  width: 73%;
  background-color: #374049;
  color: white;
  text-align: center;
  vertical-align: middle;
  padding-top: 8px;
  border-radius: 7px;
  font-weight: bold;
  margin-left: 10%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.cricketinplaynote {
  height: 20px;
  width: 30%;
  float: left;
  margin-left: 15px;
  background-color: #ffae00cb;
  color: #374049;
  padding: 1px 5px;
  border-bottom-right-radius: 70px;
  font-size: 12px;
  font-weight: bold;
}
.inplayFootballheader {
  height: 35px;
  width: 95%;
  background-color: #374049;
  color: white;
  text-align: center;
  vertical-align: middle;
  padding-top: 8px;
  margin-left: 15px;
  border-radius: 7px;
  font-weight: bold;
}
.ledgerheader {
  text-align: center;
  border: 5px solid whitesmoke;
  font-size: 13px;
  padding-top: 13px;
  background-color: #49494a;
  height: 50px;
  color: white;
  text-align: center;
}
.ledgerdatalist {
  text-align: center;
  border: 5px solid whitesmoke;
  font-size: 13px;
  padding-top: 13px;
  background-color: #f9c9d4;
  height: 50px;
  color: #49494a;
  text-align: center;
  font-weight: bold;
}
.matchname ul a {
  background-color: whitesmoke;
  text-decoration: none;
  list-style: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Neviagation side menubar item */

@media screen and (max-width: 768px) {
  .menu--open {
    transform: translate3d(0, 0, 0);
  }
  .inplayCriketheader {
    height: 35px;
    width: 92%;
    background-color: #374049;
    color: white;
    text-align: center;
    vertical-align: middle;
    padding-top: 8px;
    margin-left: 15px;
    border-radius: 7px;
    font-weight: bold;
  }
  .inplayFootballheader {
    height: 35px;
    width: 92%;
    background-color: #374049;
    color: white;
    text-align: center;
    vertical-align: middle;
    padding-top: 8px;
    margin-left: 15px;
    border-radius: 7px;
    font-weight: bold;
  }
}

/* Rule page */

.rule_main_div {
  height: 40px;
  width: 25%;
  margin-left: 72%;
  margin-bottom: 5px;
  display: block;
  background-color: whitesmoke;
  color: whitesmoke;
}

.rule_hindi {
  height: 40px;
  width: 50%;
  float: left;
  background-color: darkgrey;

  text-align: center;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
  padding-top: 8px;
  color: #fff;
}

.rule_english {
  height: 40px;
  width: 50%;
  background-color: #49494a;
  float: left;

  text-align: center;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
  padding-top: 8px;
}

.rule_text_upper p {
  text-align: center;
  color: black;
  justify-content: center;
  font-weight: bold;
  padding: 0px 20% 0px 20%;
  display: block;
}
.rule_text_lower li {
  text-align: left;
  color: rgb(34, 73, 88);
  justify-content: center;
  padding: 0px 20% 0px 20%;
  display: block;
  list-style: decimal;
}
.rule_hindi:hover {
  background-color: #131f29;
  color: whitesmoke;
}

.rule_english:hover {
  background-color: #131f29;
  color: whitesmoke;
}

@media only screen and (max-width: 768px) {
  .rule_main_div {
    height: 40px;
    width: 50%;
    margin-left: 25%;
    margin-bottom: 5px;
    display: block;
    color: whitesmoke;
    background-color: whitesmoke;
  }

  .rule_text_upper p {
    text-align: center;
    color: black;
    justify-content: center;
    padding: 0px 30px 0px 30px;
    display: block;
  }

  .rule_text_lower li {
    text-align: left;
    color: rgb(34, 73, 88);
    justify-content: center;
    padding: 0px 30px 0px 30px;
    display: block;
  }
  .rule_hindi:hover {
    background-color: #131f29;
    color: whitesmoke;
  }

  .rule_english:hover {
    background-color: #131f29;
    color: whitesmoke;
  }
}

/*Account Statement Page    
    For Desktop      */

.desktop_mobile_width {
  height: auto;
  width: 100%;
}
.statment_mainline {
  height: 30px;
  font-weight: bold;
  padding: 0px 10px;
  font-size: 15px;
  list-style: none;
  color: #983838;
  background-color: #e4e4e4;
  font-family: Tahoma, Helvetica, sans-serif;
}
.statement_containtes li {
  height: 35px;
  float: left;
  font-size: 13px;
  text-align: left;
  justify-content: center;
  list-style: none;
  padding: 6px 10px;
  border-top: 1px solid #7e97a7;
  border-bottom: 1px solid #7e97a7;
  color: #983838;
  background-color: #e4e4e4;
  font-family: Tahoma, Helvetica, sans-serif;
  font-weight: bold;
}
.statement_data li {
  height: 35px;
  float: left;
  font-size: 12px;
  text-align: left;
  justify-content: center;
  list-style: none;
  border-bottom: 1px solid #7e97a7;
  background-color: white;
  color: #131f29;
  font-family: Tahoma, Helvetica, sans-serif;
}
.header_user_lable {
  display: none;
}
.desktop_user_Exposure {
  height: 23px;
  width: auto;
  margin: 15px;
  border: 1px solid rgb(43, 80, 11);
  color: rgb(255, 132, 31);
  background-color: #374049;
  font-size: 13px;
  border-radius: 5px;
  text-align: left;
  justify-content: center;
  padding: 2px 5px;
  font-weight: normal;
  font-family: Tahoma, Helvetica, sans-serif;
  float: right;

  display: block;
}
.desktop_user_account {
  height: 23px;
  width: auto;
  margin-top: 15px;
  margin-right: 10px;
  border: 1px solid rgb(43, 80, 11);
  color: #44f019;
  background-color: #374049;
  font-size: 12px;
  border-radius: 5px;
  text-align: left;
  justify-content: center;
  padding: 2px 5px 0px 5px;
  font-weight: normal;
  font-family: Tahoma, Helvetica, sans-serif;
  float: right;
  list-style: none;
}
.account_wrap_desktop {
  position: absolute;
  background-color: #fff;
  border-radius: 4px;
  z-index: 99;
  width: 200px;
  right: 0;
  list-style: none;
  color: #131f29;
  font-size: 12px;
  font-family: Tahoma, Helvetica, sans-serif;
  line-height: 25px;
  color: rgb(59, 81, 96);
  margin-right: 10px;
  border-bottom: 1px solid rgb(126, 151, 167);
  box-shadow: 0 4px 5px rgb(0 0 0 / 50%);
  margin-top: 3px;
}

.account_wrap_desktop li {
  border-bottom: 1px solid rgb(126, 151, 167);
}
.account_wrap_desktop li a {
  height: 25px;
  width: 100%;
  padding-left: 10px;
  color: #131f29;
  font-family: Tahoma, Helvetica, sans-serif;
  text-decoration: none;
}

.account_wrap_desktop :hover {
  background-color: #e0e6e6;
  border-bottom: 1px solid rgb(126, 151, 167);
  list-style: none;
  display: block;
}
.desth_border {
  border: 1px solid gray;
  text-align: center;
  align-items: center;
}
.statement_containtes {
  width: 100%;
}
/*
    For Mobile      */

@media only screen and (max-width: 768px) {
  .statement_containtes {
    width: 200%;
  }
  .desktop_mobile_width {
    height: auto;
    width: 100%;
  }
  .statment_mainline {
    height: 30px;
    width: 100%;
    font-weight: bold;
    padding: 2px 10px;
    font-size: 15px;
    color: #983838;
    background-color: #e4e4e4;
    font-family: Tahoma, Helvetica, sans-serif;
  }
  .statement_containtes li {
    height: 43px;
    float: left;
    font-size: 11px;
    text-align: left;
    justify-content: center;
    list-style: none;
    padding: 8px 10px;
    color: #983838;
    background-color: #e4e4e4;
    font-family: Tahoma, Helvetica, sans-serif;
    border-top: 1px solid #7e97a7;
    border-bottom: 1px solid #7e97a7;
  }
  .statement_data li {
    height: 40px;
    float: left;
    font-size: 12px;
    text-align: left;
    justify-content: center;
    list-style: none;
    line-height: 16px;
    padding: 4px 10px;
    border-bottom: 1px solid #7e97a7;
    background-color: white;
    color: #131f29;
    font-family: Tahoma, Helvetica, sans-serif;
  }
  .header_user_lable {
    height: 38px;
    width: auto;
    float: right;
    /* font-weight:bold; */
    color: #35ca11;
    background-color: #374149;
    border-radius: 7px;
    text-align: left;
    margin-right: 13px;
    margin-top: 11px;
    padding: 1px 10px;
    display: block;
  }
}

/* css for match bet status page*/

.Match_status_header {
  height: 30px;
  width: auto;
  background-color: #374049;
  color: white;
  text-align: center;
  justify-content: center;
  list-style: none;
  margin: 10px 20px 10px 20px;
  padding-top: 5px;
  font-family: Tahoma, Helvetica, sans-serif;
}
.Match_status_heder_contained {
  height: 30px;
  width: auto;
  background-color: white;
  color: #131f29;
  text-align: center;
  justify-content: center;
  margin: 10px 20px 10px 20px;
  padding-top: 5px;
  font-family: Tahoma, Helvetica, sans-serif;
}
.Match_status_match_market_header {
  height: 30px;
  width: auto;
  margin: 10px 20px 0px 20px;
}
.Match_status_match_market_header li {
  height: 30px;
  width: auto;
  float: left;
  font-size: 12px;
  text-align: center;
  justify-content: center;
  list-style: none;
  color: #983838;
  background-color: #e4e4e4;
  font-family: Tahoma, Helvetica, sans-serif;
  border-top: 1px solid #7e97a7;
  border-bottom: 1px solid #7e97a7;
  padding: 5px;
}
.Match_Status_bet_data {
  height: 27px;
  width: auto;
  margin: 0px 20px 0px 20px;
}
.Match_Status_bet_data li {
  height: 27px;
  float: left;
  font-size: 10px;
  text-align: center;
  justify-content: center;
  list-style: none;
  padding: 6px 10px;
  border-bottom: 1px solid rgb(202, 200, 196);
  background-color: white;
  color: #131f29;
  font-family: Tahoma, Helvetica, sans-serif;
}
/* .overall_page_streach{
    height: auto;
    width: 60%;
    margin-left: 20%;
    
  } */

@media only screen and (max-width: 768px) {
  .Match_status_header {
    height: 30px;
    width: auto;
    background-color: #374049;
    color: white;
    text-align: center;
    justify-content: center;
    font-family: Tahoma, Helvetica, sans-serif;
    padding-top: 5px;
    margin: 10px 20px 10px 20px;
    list-style: none;
  }
  .Match_status_heder_contained {
    height: 30px;
    width: auto;
    background-color: white;
    color: #131f29;
    text-align: center;
    justify-content: center;
    font-family: Tahoma, Helvetica, sans-serif;
    padding-top: 5px;
  }
  .Match_status_match_market_header {
    height: 30px;
    width: auto;
    margin: 10px 20px 0px 20px;
  }
  .Match_status_match_market_header li {
    height: 30px;
    width: auto;
    float: left;
    font-size: 12px;
    text-align: center;
    justify-content: center;
    list-style: none;
    color: #983838;
    background-color: #e4e4e4;
    font-family: Tahoma, Helvetica, sans-serif;
    border-top: 1px solid #7e97a7;
    border-bottom: 1px solid #7e97a7;
    padding: 5px;
  }
  .Match_Status_bet_data {
    height: 27px;
    width: auto;
    margin: 0px 20px 0px 20px;
  }
  .Match_Status_bet_data li {
    height: 27px;
    float: left;
    font-size: 10px;
    text-align: center;
    justify-content: center;
    list-style: none;
    padding: 6px 10px;
    border-bottom: 1px solid rgb(202, 200, 196);
    background-color: white;
    color: #131f29;
    font-family: Tahoma, Helvetica, sans-serif;
  }
  .overall_page_streach {
    height: auto;
    width: 100%;
    margin: 0px;
  }
}
/*setting page*/
.setting_container {
  height: 300px;
  width: 80;
  margin-left: 10px;
  margin-right: 10px;
  background-color: whitesmoke;
  border-radius: 7px;
  border: 1px solid #131f29;
}
.stackHeader {
  height: 30px;
  width: 100%;
  background-color: #374149;
  color: whitesmoke;
  text-align: left;
  justify-content: center;
  padding: 4px 10px;
  font-size: 15px;
  font-family: Tahoma, Helvetica, sans-serif;
}
.defaultStack {
  height: 40px;
  width: 100%;
  /* padding-left:10px; */
  text-align: left;
  color: #131f29;
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 15px;
  padding: 10px 10px;
}
.quickstack {
  height: auto;
  width: 100%;
}
.quickstack_head {
  height: 30px;
  width: 100%;
  text-align: left;
  padding: 4px 10px;
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 15px;
}
.quickstack_amount {
  height: auto;
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
}
.quickstackbutton {
  height: 30px;
  width: 21%;
  text-align: center;
  float: left;
  background-color: #131f29;
  color: #ffb80c;
  border-radius: 5px;
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 13px;
  margin: 2.5% 2%;
}
.editstackbutton {
  height: 35px;
  width: 96%;
  text-align: center;
  float: left;
  background-color: darkgrey;
  color: #131f29;
  margin: 2.5% 2%;
  border-radius: 5px;
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 13px;
}
::placeholder {
  color: #ffb80c;
}
@media only screen and (max-width: 768px) {
  .setting_container {
    height: 300px;
    width: 80;
    margin-left: 10px;
    margin-right: 10px;
    background-color: whitesmoke;
    border-radius: 7px;
    border: 1px solid #131f29;
  }
  .quickstack_amount {
    height: auto;
    width: 100%;
  }
  .quickstackbutton {
    height: 30px;
    width: 20%;
    text-align: center;
    float: left;
    background-color: #131f29;
    color: #ffb80c;
    margin: 6px;
    border-radius: 5px;
    font-family: Tahoma, Helvetica, sans-serif;
    font-size: 13px;
  }
  .editstackbutton {
    height: 35px;
    width: 90%;
    text-align: center;
    float: left;
    background-color: darkgrey;
    color: #131f29;
    margin: 7px;
    border-radius: 5px;
    font-family: Tahoma, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: bold;
  }
  ::placeholder {
    color: #ffb80c;
  }
}
/* For Password page*/
.PasswordForm {
  height: auto;
  width: 90%;
  margin: 5%;
  border: 1px solid #131f29;
  border-radius: 7px;
  padding: 5%;
  background-color: white;
}
.change_password {
  height: 35px;
  width: 100%;
  text-align: center;
  color: #131f29;

  font-size: 17px;
  font-family: Tahoma, Helvetica, sans-serif;
  border-bottom: 1px solid lightgray;
}
.formblock {
  /* height: 90px; */
  width: 100%;
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 13px;
  padding-top: 10px;
  /* border-bottom: 1px solid lightgray; */
}
.formblock_inner {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 13px;
  padding-top: 10px;
}
.changepasswordsubmitbtn {
  height: 30px;
  width: 40%;
  text-align: center;
  float: left;
  background-color: #131f29;
  color: #ffb80c;
  border-radius: 5px;
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 14px;
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .PasswordForm {
    height: auto;
    width: 90%;
    margin: 5%;
    border: 1px solid #131f29;
    border-radius: 7px;
    padding: 5%;
    background-color: white;
  }
  .change_password {
    height: 35px;
    width: 100%;
    text-align: center;
    color: #131f29;

    font-size: 17px;
    font-family: Tahoma, Helvetica, sans-serif;
    border-bottom: 1px solid lightgray;
  }
  .formblock {
    /* height: 90px; */
    width: 100%;
    font-family: Tahoma, Helvetica, sans-serif;
    font-size: 13px;
    padding-top: 10px;
    /* border-bottom: 1px solid lightgray; */
  }
  .formblock_inner {
    font-family: Tahoma, Helvetica, sans-serif;
    font-size: 13px;
    padding-top: 10px;
  }
  .changepasswordsubmitbtn {
    height: 30px;
    width: 40%;
    text-align: center;
    float: left;
    background-color: #131f29;
    color: #ffb80c;
    border-radius: 5px;
    font-family: Tahoma, Helvetica, sans-serif;
    font-size: 14px;
    margin-top: 10px;
  }
}

/*Login main page */
.login_app_strech {
  height: auto;
  width: 60%;
  margin-left: 35%;
  margin-right: 20%;
  background-color: black;
  /* margin-top: 35px; */
}

.Login_desktop {
  height: 600px;
  width: 100%;
  display: block;
}
.Login_mobile {
  height: 600px;
  width: 100%;
  display: none;
}
.backgroundslider {
  height: 585px;
  width: auto;
  background-image: url("./image/mobileimage.png");
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: 450px;
}
.login_division_section {
  position: absolute;
  background-color: black;
  height: 351px;
}
.xyz {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .login_division_section {
    width: 100%;
    float: left;
  }
  .login_app_strech {
    height: 100vh;
    width: 100%;
    background: rgb(152 55 55);
    margin-left: 0%;
    margin-right: 0%;
  }
  .backgroundslidermobile {
    height: 550px;
    width: 100%;
    background-image: url("./image/mobileimage.png");
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: 400px;
    position: fixed;
  }
  .mobilesliderform {
    height: 385px;
    width: 61%;
    margin-left: 109px;
    margin-top: 110px;
    background-color: lightpink;
  }

  .Login_mobile {
    height: 600px;
    width: 100%;
    display: block;
  }
  .Login_desktop {
    height: 600px;
    width: 100%;
    display: block;
  }
}

/* @media only screen and (min-width: 480px){
  .login_app_strech{
    height: auto;
    width: 100%;
    margin-left: 0%;
    margin-right:0%;
    margin-top: 40px;
    padding-left: 50px;
     
    }
  
  }
@media only screen and (min-width: 768px){
  .login_app_strech{
    height: auto;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 50px;
  }
  
  }
@media only screen and (min-width: 992px){
  .login_app_strech{
    height: auto;
    width: 52.5%;
    margin-left: 23.75%;
    margin-right: 23.75%;
    margin-top: 50px;
  }
  
  }
   @media only screen and (min-width: 992px){
    .login_app_strech{
      height: auto;
      width: 52.5%;
      margin-left: 23.75%;
      margin-right: 23.75%;
      margin-top: 50px;
    }
    
    } 





*/
.mobileLoging {
  /* padding: 10px;
  margin-top: 40%;  */
  padding: 30px 10px;
  height: auto;
  border-radius: 9px;
  width: 141%;
  background-color: rgb(152, 56, 56);
  top: 135px;
  position: relative;
  left: 34px;

  justify-content: center;
}

.purp_btn {
  left: 7.4rem !important;
}
@media only screen and (max-width: 768px) {
  .purp_btn {
    left: 28vw !important;
  }
}

@media only screen and (max-width: 768px) {
  .backgroundslider {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .mobileLoging {
    padding: 10px;

    width: 100% !important;
    /* margin-top: 40%;  */

    justify-content: center;
  }
}
@media only screen and (max-width: 768px) {
  .mobileLoging {
    left: 0px;
    padding-top: 53px;
    top: 18px;
  }
}
@media only screen and (max-width: 768px) {
  .login_app_strech {
    margin-top: -19px;
  }
}

@media (max-width: 768px) {
  .header-top-spacing {
    padding-top: 10px;
  }
}

@media (max-width: 768px) {
  .header-top-spacing {
    padding-top: 10px !important;
  }
}
@media (max-width: 768px) {
  .header-top-spacing {
    padding-top: 10px;
  }
  .header-top-spacing {
    padding-top: 15px;
  }
}

@media (max-width: 768px) {
  .match-text-flag-box .row {
    margin: auto auto 15px !important;
  }
}

@media (max-width: 767px) {
  .top-time-dtl {
    display: block;
    font-size: 14px;
  }
}
.top-time-dtl {
  background-color: rgb(58, 97, 162);
  color: rgb(255, 255, 255);
  padding: 10px;
  text-align: center;
  /* display: none; */
}
.col-xs-12 {
  width: 100%;
}
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

/* @media (max-width: 767px){
.in-play-row-left {
  border-top-left-radius: 0em;
 
}
} */

.in-play-row-left {
  background-color: #fff;
  /*
 */
  height: 118px;
}

@media (max-width: 767px) {
  .in-play-row-right {
    display: none;
  }
}

@media (max-width: 767px) {
  .in-play-row-right {
    background-color: #983838;
    padding: 3px;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
}
.in-play-row-right {
  background-color: #983838;
  padding: 11px;

  height: 118px;
}
/* .col-xs-3 {
  width: 25%;
} */
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}

.in-play-row-right > .match-time-dtl {
  color: #fff;
  text-align: center;
  margin-top: 8px;
}

.match-time-dtl-date {
  font-family: "open-sans-regular" !important;
  color: #fff;
}

.match-time-dtl-mounth {
  font-family: "open-sans-light" !important;
  color: #fff;
}

match-time-dtl-mounth {
  font-family: "open-sans-light" !important;
  color: #fff;
}

.in-play-right-box-color {
  background-color: #983838 !important;
  color: #fff;
}

@media (max-width: 768px) {
  .CFH-Btn-outer .cricket-button,
  .CFH-Btn-outer .football-button,
  .CFH-Btn-outer .horse-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto !important;
    padding: 8px 5px 5px !important;
    flex: 1;
  }
}
.cricket-button {
  /* background-image: url(../images/play.png); */
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 30px 30px;
  padding: 10px;
  width: 130px;
  border-style: solid;
  outline: none;
  background-color: #983838;
  border-color: #ffffff;
  color: #ffffff;
  border: 0px solid;
  border-radius: 7px;
  font-weight: 600;
  font-family: inherit !important;
  font-size: 0.9em !important;
  margin-right: 5px;
  line-height: 25px;
}

.top-time-dtl {
  background-color: rgb(58, 97, 162);
  color: rgb(255, 255, 255);
  padding: 10px;
  text-align: center;
}

/* 
.in-play-row-right {
  background-color: #983838;
  padding: 11px;
 
 
  height:85px;
} */

@media (min-width: 1200px) {
  .col-lg-2 {
    width: 16.66666667%;
  }
}

@media (min-width: 1200px) {
  .col-lg-10 {
    width: 83.33333333%;
  }
}

@media (min-width: 768px) {
  .col-sm-9 {
    width: 75%;
  }
}
@media (min-width: 768px) {
  #head-matches {
    display: none !important;
  }
}

iframe {
  height: 217px !important;
}
@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9 {
    float: left;
  }
}

.flags-container {
  width: auto !important;
}

@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9 {
    float: left;
  }
}

.match-detail-container .match-title p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.row.matchBox-Row {
  margin-bottom: 15px;
}
.match-info p {
  margin: 0px 0px 0px !important;
}

.match-title > .match-info {
  color: #676767;
  font-family: "open-sans-light" !important;
  font-size: 13px !important;
}

@media screen and (max-width: 40em) {
  body,
  .container {
    height: auto;
    overflow: auto;
  }
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.biab_bet-matched {
  position: absolute;
  width: 100%;
}

@media (max-width: 999px) {
  .biab_game .biab_bet-matched {
    position: fixed;
    left: 50%;
    z-index: 1020;
    width: 100%;
    margin-left: -50%;
  }
}

.biab_matching-bet-msg {
  background-color: #e9a20a;
  position: absolute;
  right: 10px;
  left: 10px;
  padding: 9px;
  min-height: 39px;
  z-index: 1020;
}

.biab_matching-bet-msg.biab_cancelled-bet {
  min-height: 22px;
}
.biab_matching-bet-msg.biab_type-bet {
  background-color: #000000;
}
.biab_matching-bet-msg {
  background-color: #e9a20a;
  position: absolute;
  right: 10px;
  left: 10px;
  padding: 9px;
  min-height: 39px;
  z-index: 1020;
}
.biab_matching-bet-msg .biab_matched-label,
.biab_matching-bet-msg .biab_matched-details {
  display: block;
  color: #fff;
  width: 100%;
  font-size: 14px;
  line-height: 22px;
}
.biab_matching-bet-msg .biab_matched-label a,
.biab_matching-bet-msg .biab_matched-details a {
  color: #fff;
  text-decoration: underline;
}
.biab_matching-bet-msg .biab_matched-label {
  font-weight: 700;
}
.biab_matching-bet-msg .biab_matched-details {
  color: #fff;
  font-size: 16px;
}

.bl-Preloader_Spinner {
  box-sizing: border-box;
  width: 45px;
  height: 45px;
  margin: 0 auto;
  border: 3px solid #d8d2d2;
  border-left: 4px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-animation: bl-Spinner 0.6s linear infinite;
  animation: bl-Spinner 0.6s linear infinite;
}

@keyframes bl-Spinner {
  0% {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  100% {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
}

.bl-Preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #27252585;
  z-index: 99;
  justify-content: center;
  align-items: center;
}

.left-menu-slide {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #983838;
  z-index: 1500;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s ease;
}
.cricket-btn-color-change,
.football-btn-color-change,
.cricket-button,
.football-button {
  background-image: none !important;
  padding: 9px !important;
}
.cricket-button {
  /* background-image: url(../images/play.png); */
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 30px 30px;
  padding: 10px;
  width: 130px;
  border-style: solid;
  outline: none;
  background-color: #983838;
  border-color: #ffffff;
  color: #ffffff;
  border: 0px solid;
  border-radius: 7px;
  font-weight: bold;
  font-size: 16px;
  font-family: "open-sans-semibold";
  margin-right: 5px;
  line-height: 25px;
}
@media (max-width: 768px) {
  .CFH-Btn-outer .cricket-button,
  .CFH-Btn-outer .football-button,
  .CFH-Btn-outer .horse-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto !important;
    padding: 8px 5px 5px !important;
    flex: 1;
  }
}
.CFH-Btn-outer {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* logincss */
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container:before {
  display: table;
  content: " ";
}
.col-centered {
  float: none !important;
  margin: 0 auto !important;
}

@media (min-width: 992px) {
  .col-md-9 {
    width: 75%;
  }
}
.ldg-tbl-th {
  text-align: center;
  background-color: #983838;
  color: #fff;
  padding: 15px !important;
  font-weight: bold;
  letter-spacing: 1px;
  font-family: sans-serif;
  border: 5px solid #e9e9e9 !important;
}
/* match score */
.match-status-table[_ngcontent-gyh-c22] {
  height: 100px !important;
}
.content_in_play_wrapper[_ngcontent-gyh-c22] {
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  border-right: solid 5px #eee;
}

.match-status-table[_ngcontent-gyh-c22] {
  display: table !important;
  line-height: 1.5;
}
.col-xs-9 {
  /* width: 75%; */
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
@media (max-width: 767px) {
  .inplay-show-tbl-td[_ngcontent-gyh-c22] {
    color: #fff;
    font-family: open-sans-regular !important;
    font-weight: 600;
  }
}
.match-status-text[_ngcontent-gyh-c22] {
  display: table-cell !important;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 0 10px !important;
}
@media (max-width: 767px) {
  .spfont[_ngcontent-gyh-c22] {
    width: 100% !important;
  }
}
@media (max-width: 767px) {
  .spfont[_ngcontent-gyh-c22] {
    width: 100% !important;
  }
}
@media (max-width: 767px) {
  .spfont[_ngcontent-gyh-c22] {
    width: 100% !important;
  }
}
.bet_status_box[_ngcontent-gyh-c22] {
  height: 100px !important;
  min-height: 100%;
  height: 100%;
  border-right: solid 10px #eee;
  padding: 0;
  line-height: 1;
}
.col-xs-3 {
  width: 25%;
  float: left;
}
.event[_ngcontent-gyh-c22] {
  width: 100%;
  margin-bottom: 0;
}
.bet-status-text-p[_ngcontent-gyh-c22] {
  text-align: center !important;
  vertical-align: middle !important;
  color: #fff;
  font-family: "Open Sans", sans-serif !important;
}
#cricketScore {
  height: 104px;
}
.match-status-text[_ngcontent-gyh-c22] {
  display: table-cell !important;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 0 10px !important;
}
#refresh2[_ngcontent-gyh-c22] {
  cursor: pointer;
}
.refresh-btn.reload-class[_ngcontent-gyh-c22] {
  height: 100px !important;
}
div.refresh-btn-color[_ngcontent-gyh-c22] {
  text-align: center;
}
.refresh-btn[_ngcontent-gyh-c22] {
  float: right;
  padding: 14px;
  position: relative;
  right: 5px;
  min-height: 100%;
  height: 100%;
}
/* @media (min-width: 768px){
.col-sm-2 {
  width: 16.66666667%;
}} */
.refresh_img[_ngcontent-gyh-c22] {
  position: relative;
  top: 14px;
  font-size: 35px !important;
  color: #fff;
}
.refresh-btn.reload-class[_ngcontent-gyh-c22] {
  height: 100px !important;
}
.inplay-show-tbl-td[_ngcontent-gyh-c22] {
  color: #fff;
  padding: 10px;
  font-family: open-sans-regular !important;
  font-weight: 600;
}
.match-status-text[_ngcontent-gyh-c22] {
  display: table-cell !important;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 0 10px !important;
}
.match-status-table[_ngcontent-gyh-c22] {
  height: 100px !important;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  border-right: solid 5px #eee;
}
/* betslip */
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
}
.modalbet {
  position: fixed;
  top: auto;
  right: auto;
  left: auto;
  bottom: 0;
  margin: 0;
}
@media (min-width: 768px) {
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
    box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
  }
}
.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
  box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
}
.modal-body {
  position: relative;
  padding: 15px;
}
.table {
  table-layout: inherit;
}
.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
table.market-rate-table {
  width: 100%;
  background-color: #e9e9e9;
  border-collapse: collapse;
  border: 2px solid #3a61a2;
  border-bottom: 0px solid;
}
table {
  border-spacing: 0;
  /* border-collapse: collapse; */
}
.market-rate-table td {
  width: 33%;
  text-align: center;
  font-family: "open-sans-bold";
  border: 2px solid #3a61a2;
  border-bottom: 0px solid;
  height: 40px;
  font-size: 12px;
  color: #3e3e3e;
}
.table td,
.table th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (orientation: portrait) {
  .cls-td {
    height: inherit;
    padding-top: 10px;
    padding-bottom: inherit;
    font-family: sans-serif;
  }
}
.cls-td {
  background-color: #e9e9e9;
}
.form-control {
  padding: 12px 12px;
}
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.table {
  table-layout: inherit;
}
.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
.min-max-stack-table {
  border: 1px solid #3a61a2;
  color: #fff;
}
@media (orientation: portrait) {
  .min-max-stack-table {
    display: table !important;
  }
}
.num-pad {
  text-align: center;
  width: 100%;
  font-size: 15px;
  font-family: "open-sans-bold";
  border: 1px solid #3a61a2;
  border-collapse: collapse;
}
table.min-max-stack-table tr.bet-values-row td {
  width: 20%;
}
table.min-max-stack-table tr.bet-values-row td {
  background-color: #c96e6c;
}
.num-pad tr td {
  border: 1px solid #223f6f;
  border-bottom: none;
  background-color: #e9e9e9;
}
table.min-max-stack-table td {
  border: 1px solid #3a61a2;
  /* padding: 16px 0; */
  font-size: 13px;
}
table td,
.table th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (orientation: portrait) {
  .cls-td {
    height: inherit;
    padding-top: 10px;
    padding-bottom: inherit;
    font-family: sans-serif;
  }
}
table.numpad-table {
  background-color: rgba(233, 233, 233, 0.75);
}
@media (orientation: portrait) {
  .numpad-table {
    width: 100% !important;
    float: inherit;
  }
}
.num-pad {
  text-align: center;
  width: 100%;
  font-size: 15px;
  font-family: "open-sans-bold";
  border: 1px solid #3a61a2;
  border-collapse: collapse;
}
.num-pad tr td {
  border: 1px solid #223f6f;
  border-bottom: none;
  background-color: #e9e9e9;
}
.score_main_div {
  height: 60px;
  width: 100%;
  background-color: #983838;
  color: white;
}
.score_main_div_left {
  height: 60px;
  width: 50%;
  border-right: 1px solid white;
}
.score_main_div_right {
  height: 60px;
  width: 50%;
  border-left: 1px solid white;
}
